<template>
  <div class="con">
    <v-container>
      <v-form>
        <Loading v-if="isLoading" />
        <v-row>
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="items"
              hide-default-footer>
              <template #no-data>
                {{ $t('global.notFound') }}
              </template>
              <template #item.orderId="{ item }">
                <router-link :to="`/history/${item.orderId}?type=${item.isGroup ? 'group' : 'normal'}`">
                  {{ item.orderId }}
                </router-link>
              </template>
              <template #item.branch="{ item }">
                {{ item.branch === 'trang' ? $t('global.trang') : $t('global.sathon') }}
              </template>
              <template #item.date="{ item }">
                {{ item.schedule.date | formatMiniDate }}
              </template>
              <template #item.time="{ item }">
                <p>
                  {{ formatSchedule(item.schedule.startTime, item.schedule.endTime) }}
                </p>
              </template>
              <template #item.status="{ item }">
                <div :class="statusColor(item.orderStatus)">
                  {{ $t(`history.orderStatus.${item.orderStatus}`) }}
                </div>
              </template>
              <template #item.atkStatus="{ item }">
                <div>
                  <div
                    v-if="item.atkStatus"
                    :class="atkStatusColor(item.atkStatus)">
                    {{ $t(`global.atkStatusTrue`) }}
                  </div>
                  <div
                    v-else
                    :class="atkStatusColor(item.atkStatus)">
                    {{ $t(`global.atkStatusFalse`) }}
                  </div>
                </div>
              </template>
              <template
                #item.detail="{ item }"
              >
                <v-row
                  v-if="!item.isGroup && item.orderStatus === 'waiting' && !isRejectOrCalcel(item.oderStatus) && !isExpired(item.schedule.date) && item.price.total > 0"
                  class="mt-2"
                >
                  <v-col
                    class="px-0 pb-4"
                    cols="12">
                    <v-btn
                      :to="`/history/${item.orderId}?type=${item.isGroup ? 'group' : 'normal'}&actions=payment`"
                      color="primary"
                      class="font-weight-bold"
                      height="65"
                      block
                    >
                      <v-icon
                        size="26"
                        left>
                        mdi-credit-card-outline
                      </v-icon>
                      {{ $t('global.checkout') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-0 my-0">
                  <v-col
                    :cols="$vuetify.breakpoint.width <= 370 ? 12 : 6"
                    class="pb-0 px-0"
                    :class="$vuetify.breakpoint.width <= 370 ? 'pr-0' : 'pr-1'"
                  >
                    <v-btn
                      color="#39aef7"
                      dark
                      block
                      depressed
                      height="70"
                      :to="`/history/${item.orderId}?type=${item.isGroup ? 'group' : 'normal'}`"

                    >
                      <v-avatar
                        size="33"
                        color="white"
                        class="mr-1"
                      >
                        <v-icon
                          class="bg"
                          color="#39aef7"
                        >
                          mdi-account-details
                        </v-icon>
                      </v-avatar>

                      <div class="d-flex flex-column justify-center align-start">
                        <div>
                          {{ $i18n.locale === 'th' ? $t('booking.bookingMoreDetail').slice(0,10) : $t('booking.bookingMoreDetail').slice(0,7) }}
                        </div>
                        <div>
                          {{ $i18n.locale === 'th' ? $t('booking.bookingMoreDetail').slice(10,30) : $t('booking.bookingMoreDetail').slice(7,30) }}
                        </div>
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.width <= 370 ? 12 : 6"
                    class="pb-0 px-0"
                    :class="$vuetify.breakpoint.width <= 370 ? 'pl-0' : 'pl-1'"
                  >
                    <a

                      :href="`https://calendar.google.com/calendar/r/eventedit?text=Plan+Toys&dates=${startDate(item.schedule.date, item.schedule.startTime, item.isGroup)}/${endDate(item.schedule.date, item.schedule.endTime, item.isGroup)}&details=This+is+Detail`"
                      target="_blank"
                      class="text-decoration-none"
                    >
                      <v-btn
                        block
                        color="#5bc0ff"
                        class="white--text"
                        height="70"
                        depressed
                        small
                      >
                        <v-avatar
                          size="33"
                          color="white"
                          left
                          class="mr-2"
                        >
                          <v-icon
                            class="bg"
                            color="#5bc0ff"
                          >
                            mdi-calendar-month-outline
                          </v-icon>

                        </v-avatar>

                        <div class="d-flex flex-column justify-center align-start">
                          <div>
                            {{ $i18n.locale === 'th' ? $t('global.addToCalendar').slice(0,100) : $t('global.addToCalendar').slice(0,6) }}
                          </div>
                          <div>
                            {{ $i18n.locale === 'th' ? '': $t('global.addToCalendar').slice(6,30) }}
                          </div>
                        </div>
                      </v-btn>
                    </a>
                  </v-col>
                </v-row>
                <v-row
                  v-if="!isRejectOrCalcel(item.orderStatus) && !isExpired(item.schedule.date)"
                >
                  <v-col
                    v-if="item.isGroup"
                    :cols="$vuetify.breakpoint.width <= 370 ? 12 : 6"
                    :class="$vuetify.breakpoint.width <= 370 ? 'pr-0' : 'pr-1'"
                    class="pt-0 px-0"
                  >
                    <v-btn
                      v-if="!item.isGroup"
                      :to="`/atk/${item.orderId}`"
                      color="#52aae1"
                      class="white--text px-1"
                      height="70"
                      block
                      depressed>
                      <v-avatar
                        size="33"
                        color="white"
                        class="mr-1"
                      >
                        <v-icon color="#52aae1">
                          mdi-needle
                        </v-icon>
                      </v-avatar>
                      <div class="d-flex flex-column justify-center align-start font-12">
                        <div>
                          {{ $i18n.locale === 'th' ? $t('global.atkInfo').slice(0,11) : $t('global.atkInfo').slice(0,15) }}
                        </div>
                        <div>
                          {{ $i18n.locale === 'th' ? $t('global.atkInfo').slice(11,40) : $t('global.atkInfo').slice(15,50) }}
                        </div>
                      </div>
                    </v-btn>
                    <v-btn
                      v-else
                      :to="`/history/file/${item.orderId}`"
                      color="#52aae1"
                      class="white--text pr-1 font-8"
                      block
                      height="70"
                      depressed>
                      <v-avatar
                        size="33"
                        color="white"
                        class="mr-1"
                      >
                        <v-icon color="#52aae1">
                          mdi-file-document
                        </v-icon>
                      </v-avatar>
                      <div class="d-flex flex-column justify-center align-start">
                        <div>
                          {{ $i18n.locale === 'th' ? $t('booking.visitConsentSend').slice(0,16) : $t('booking.visitConsentSend').slice(0,11) }}
                        </div>
                        <div>
                          {{ $i18n.locale === 'th' ? $t('booking.visitConsentSend').slice(16,30) : $t('booking.visitConsentSend').slice(11,30) }}
                        </div>
                      </div>
                    </v-btn>
                  </v-col>
                  <v-col
                    :cols="$vuetify.breakpoint.width <= 370 ? 12 : 6"
                    :class="$vuetify.breakpoint.width <= 370 ? 'pl-0' : 'pl-1'"
                    class="pt-0 px-0"
                  >
                    <v-btn
                      v-if="item && !item.isGroup && isChangeSchedule(item.orderStatus) && !isExpired(item.schedule.date) && scheduleBook(item.schedule.date) > 1"
                      color="orange"
                      outlined
                      dark
                      block
                      class="px-4"
                      height="70"
                      :to="`/history/change/${item.orderId}?type=${item.type}`">
                      <v-icon>
                        mdi-repeat
                      </v-icon>
                      <div class="font-12">
                        {{ $t('booking.changeSchedule') }}
                      </div>
                    </v-btn>
                    <v-btn
                      v-if="item && item.isGroup && isChangeSchedule(item.orderStatus) && !isExpired(item.schedule.date) && scheduleBook(item.schedule.date) > 5"
                      color="orange"
                      outlined
                      dark
                      block
                      class="px-4"
                      height="70"
                      :to="`/history/change/${item.orderId}?type=group`">
                      <v-icon>
                        mdi-repeat
                      </v-icon>
                      <div class="font-12">
                        {{ $t('booking.changeSchedule') }}
                      </div>
                    </v-btn>
                  </v-col>
                </v-row>
                <div class="mb-4">
                </div>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import Loading from '@/components/Loading.vue'

dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.tz.setDefault('Asia/Bangkok')

export default {
  components: {
    Loading
  },
  data () {
    return {
      items: [],
      isLoading: false
    }
  },
  computed: {
    headers () {
      return [
        {
          text: 'ID',
          align: 'start',
          sortable: false,
          value: 'orderId'
        },
        {
          text: this.$t('global.branch'),
          align: 'start',
          sortable: false,
          value: 'branch'
        },
        {
          text: this.$t('global.date'),
          align: 'start',
          sortable: false,
          value: 'date'
        },
        {
          text: this.$t('global.time'),
          align: 'start',
          sortable: false,
          value: 'time',
          width: '9rem'
        },
        {
          text: this.$t('global.status'),
          align: 'start',
          sortable: false,
          value: 'status',
          width: '7rem'
        },
        // {
        //   text: this.$t('global.atkStatusDetailShort'),
        //   align: 'start',
        //   sortable: false,
        //   value: 'atkStatus',
        //   width: '14rem'
        // },
        {
          text: '',
          sortable: false,
          value: 'detail',
          width: '22rem'
        }
      ]
    }

  },
  async mounted () {
    // this.checkLogined()
    await this.getOrder()
  },
  methods: {
    scheduleBook (date) {
      return dayjs(date).diff(dayjs(), 'day')
    },
     isRejectOrCalcel (status) {
      if (status === 'rejected' || status === 'canceled') {
        return true
      }
      return false
    },
    isChangeSchedule (status) {
      if (status === 'waiting' || status === 'paid') {
        return true
      }
      return false
    },
    isExpired (date) {
      const diff = dayjs(date).diff(dayjs(), 'day')
      if (diff < 0) {
        return true
      }
      return false
    },
     startDate (date, _startTime, isGroup) {
      if (date && !isGroup) {
        const startTime = _startTime.split(':')
        const dates = new Date(date)
        return new Date(dates.setHours(startTime[0], startTime[1])).toISOString().replace(/([.:-])/g, '')
      }
      return ''
    },
    endDate (_date, _endTime, isGroup) {
      if (_date && !isGroup) {
        const endTime = _endTime.split(':')
        const date = new Date(_date)
        return new Date(date.setHours(endTime[0], endTime[1])).toISOString().replace(/([.:-])/g, '')
      }
      return ''
    },
    statusColor (value) {
      switch (value) {
        case 'waiting':
          return 'orange--text'
        case 'pending':
          return 'orange--text'
        case 'paid':
          return 'green--text'
        case 'canceled':
          return 'red--text'
        case 'rejected':
          return 'red--text'
        default:
          return ''
      }
    },
    atkStatusColor (value) {
      switch (value) {
        case true:
          return 'green--text'
        case false:
          return 'orange--text'
        default:
          return ''
      }
    },
    async getOrder () {
      try {
        this.isLoading = true
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/order/member/${member.memberId}`)
        this.items = data
      } catch (error) {
        this.error = error
      } finally {
        this.isLoading = false
      }
    },
    async checkLogined () {
      try {
        const { member } = JSON.parse(localStorage.getItem('member'))
        const { data: { data } } = await this.axios.get(`${process.env.VUE_APP_API_URL}/member/${member.memberId}`)
        if (!data.member) {
          localStorage.removeItem('member')
          this.$router.push('/')
        }
      } catch (error) {
        console.error(error)
      }
    },
    formatSchedule (startTime, endTime) {
      if (startTime === '00:00' && endTime === '23:59') {
        return 'Allday'
      }
      return `${startTime} - ${endTime}`
    }
  }
}
</script>
<style scoped>
.con table tbody tr td:last-child .v-data-table__mobile-row__header {
 display: none ;
}
.con table tbody tr td:last-child .v-data-table__mobile-row__cell{
 width: 100%;
}
.font-12 {
  font-size: 11px;
}
.font-8 {
  font-size: 8px;
}
</style>
